import { configureStore, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

interface ContactInfo {
  phone: string;
  email: string | null;
  website: string;
  instagram: string;
  facebook: string;
  tiktok: string;
  loading: boolean;
}

const initialState: ContactInfo = {
  phone: "",
  email: null,
  website: "",
  instagram: "",
  facebook: "",
  tiktok: "",
  loading: false,
};

// Define fetchSocials before using it in the slice
const fetchSocials = createAsyncThunk("socials/fetchSocials", async () => {
  const res = await fetch("https://apirafal.cyparta.com/contact-us/");
  const data = await res.json();
  return data;
});

const socialSlice = createSlice({
  name: "socials",
  initialState,
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocials.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSocials.fulfilled, (state, action) => {
        state.loading = false;
            Object.assign(state, action.payload);      })
      .addCase(fetchSocials.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Create Redux store


export default socialSlice

export { fetchSocials };
