import { Box, Typography } from "@mui/material";
import { t } from "i18next";
import { useEffect, useState } from "react";


interface shipping {
  id:number
  shipping:number
}

const CheckoutPrice = ({ cartUser }: any) => {
  const [shippingPrice, setShippingPrice] = useState<number | null>(null);

  useEffect(() => {
    const fetchShippingPrice = async () => {
      try {
        const response = await fetch("https://apirafal.cyparta.com/shipping/");
        if (!response.ok) {
          throw new Error("Failed to fetch shipping price");
        }
        const data:shipping[] = await response.json();
        setShippingPrice(data[0].shipping);
        console.log(shippingPrice)
      } catch (error) {
        console.error("Error fetching shipping price:", error);
      }
    };

    fetchShippingPrice();
  }, []);

  return (
    <Box>
      <Box mt="32px">
        <Typography
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: "500",
            mb: "10px",
            color: "#505050",
          }}
        >
          {t("shipping")} : {shippingPrice !== null ? shippingPrice : "—"} {t("egp")}
        </Typography>

        <Typography
          sx={{
            fontSize: { xs: "16px", md: "18px" },
            fontWeight: "500",
            mb: "10px",
            color: "#505050",
          }}
        >
          {t("products")} : {cartUser?.total_products_price} {t("egp")}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          background: "#50505080",
          height: "1px",
          mt: "16px",
        }}
      ></Box>

      <Typography
        sx={{
          fontSize: { xs: "14px", sm: "16px", md: "20px" },
          fontWeight: "600",
          mt: "16px",
        }}
      >
        {t("total")} : {cartUser?.total_products_price + (shippingPrice ?? 0)} {t("egp")}
      </Typography>
    </Box>
  );
};

export default CheckoutPrice;
