import React, { useState } from "react";
import { IconBox } from "../../style/style";
import { Box, Typography } from "@mui/material";
import recommendImage from "../../assets/home/recommed-1.png";

import bestSeller2 from "../../assets/home/best-seller-2.png";

import bestSeller2Ar from "../../assets/home/best-seller-ar.png";
import bestOfferAr from "../../assets/home/best-offer-ar.png";

import newOffer from "../../assets/home/new-offer.png";
import outOfStock from "../../assets/product/out-of-stuck.png";
import { colors } from "../../utils/theme";
import StarIcon from "@mui/icons-material/Star";
import { BiTransfer } from "react-icons/bi";
import { BsBookmark } from "react-icons/bs";

import "../../style/productCard.css";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { t } from "i18next";

import useCustomHooks from "../../hooks/customHooks";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import useAddToCart from "../../hooks/useAddToCart";
import useCompare from "../../hooks/useCompare";
import { toast } from "react-toastify";
import { getSlugFiy } from "../../utils/helper-function";

const ProductCard = ({ type = "", image = recommendImage, product }: any) => {
  const query = useSearchParams();
  // hovering
  const [isHovering, setIsHovering] = useState(false);
  const [isHoveringSaved, setIsHoveringSaved] = useState(false);
  const [isHoveringTransfer, setIsHoveringTransfer] = useState(false);

  // colors
  const { dark, blue, card } = colors;

  // dispatch
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  // lang
  const lang = useSelector((state: RootState) => state.global.mainLang);

  // token
  const user = useSelector((state: RootState) => state.user.user);

  // ADD To Wishlist
  const handleConsole = useCustomHooks();
  const handleCompare = useCompare();

  // ADD to cart
  const handleAddItem = useAddToCart();

  // hovering
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  // handle add To Cart
  const handleAddToCart = () => {
    if (product?.color?.length > 0) {
      handleAddItem(
        1,
        product?.id,
        product?.color[0]?.hex_value,
        product?.color[0]?.images[0]?.image,
        product?.name
      );
    } else {
      handleAddItem(1, product?.id, null, product?.image, product?.name);
    }
  };

  const handleAddToWishList = () => {
    if (user) {
      handleConsole(product?.id, product?.is_wishlist);
    } else {
      dispatch(setOpenLogin(true));
      dispatch(setSignType("login"));
    }
  };

  // handle add to compare
  const handleAddToCompare = () => {
    if (user) {
      handleCompare(product?.id, product?.is_comparison);
    } else {
      dispatch(setOpenLogin(true));
    }
  };

  return (
    <>
      <Box
        sx={{
          position: "relative",
          boxShadow:
            "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)", // may remove it
          borderRadius: "8px", // may remove it
          direction: lang == "en" ? "ltr" : "rtl",
        }}
      >
        <Box
          sx={{
            background: card[100],
            padding: "16px",
            position: "relative",
            borderRadius: "8px 8px 0 0",
          }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          <Box
            sx={{
              textAlign: "center",
              mt: "0px",
              display: "flex",
              justifyContent: "center",
              minHeight: "200px",
            }}
          >
            <img
              src={
                product?.color?.length > 0
                  ? product?.color[0]?.images[0]?.image.replace(/ /g, "%20")
                  : product?.image.split(" ")[0].replace(/ /g, "%20")
              }
              alt="recommend"
              loading="lazy"
              style={{ width: "166px", height: "200px" }}
            />
          </Box>
          <Typography
            sx={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              color: dark[100],
              fontWeight: "600",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              whiteSpace: "nowrap",
              display: "block",
              mt: "16px",
            }}
          >
            {product?.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              mt: "7px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <StarIcon
                sx={{ fontSize: "16px", color: "rgba(240, 194, 77, 1)" }}
              />
              <Typography component={"span"}>{product?.avg_rating}</Typography>
            </Box>
            <Box
              sx={{
                width: "3px",
                height: "3px",
                background: "#D9D9D9",
                borderRadius: "50%",
              }}
            ></Box>
            <Typography>( {product?.total_ratings_count} )</Typography>
          </Box>
          <Box sx={{ mt: "15px" }}>
            <Typography
              component="span"
              sx={{ color: blue[200], fontWeight: "600", fontSize: "14px" }}
            >
              {product?.price} {t("egp")}
            </Typography>
            <Typography
              component="span"
              sx={{
                textDecoration: "line-through",
                paddingLeft: "10px",
                paddingRight: "10px",
                fontWeight: "400",
                fontSize: "14px",
              }}
            >
              {product?.old_price > 0 && product?.old_price}
            </Typography>
          </Box>
          <Link to={`/shop/${getSlugFiy(product?.name)}/?id=${product?.id}`}>
            <Box
              sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "rgba(27, 27, 27, 0.1)",
                display: "flex",
                flexDirection: "column",
                visibility: isHovering ? "visible" : "hidden",
                opacity: isHovering ? "1" : "0",
                transition: "all ease-out  300ms",
                borderRadius: "8px 8px 0 0",
              }}
            >
              <IconBox
                sx={{
                  mt: "16px",
                  mb: "12px",
                  ml: "16px",
                  mr: "16px",
                  background:
                    isHoveringSaved || product?.is_wishlist
                      ? "linear-gradient(90deg, #F5841F 0.35%, #ED1C24 99.64%) !important"
                      : "#fff",
                  color:
                    isHoveringSaved || product?.is_wishlist ? "#fff" : "#000",
                }}
                onMouseOver={() => setIsHoveringSaved(true)}
                onMouseOut={() => setIsHoveringSaved(false)}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddToWishList();
                }}
              >
                <BsBookmark style={{ fontSize: "18px" }} />
              </IconBox>
              <IconBox
                sx={{
                  mr: "16px",
                  ml: "16px",
                  background:
                    isHoveringTransfer || product?.is_comparison
                      ? "linear-gradient(90deg, #F5841F 0.35%, #ED1C24 99.64%) !important"
                      : "#fff",
                  color:
                    isHoveringTransfer || product?.is_comparison
                      ? "#fff"
                      : "#000",
                }}
                onMouseOver={() => setIsHoveringTransfer(true)}
                onMouseOut={() => setIsHoveringTransfer(false)}
                onClick={(e) => {
                  e.preventDefault();
                  handleAddToCompare();
                }}
              >
                <BiTransfer style={{ fontSize: "18px" }} />
              </IconBox>
            </Box>
          </Link>

          {product?.is_best_seller ? (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                right: lang == "en" ? "0" : "auto",
                left: lang == "en" ? "auto" : "0",
              }}
            >
              <img
                src={lang === "en" ? bestSeller2 : bestSeller2Ar}
                alt="best seller"
              />
            </Box>
          ) : product?.is_offer ? (
            <Box
              sx={{
                position: "absolute",
                top: "0",
                right: lang == "en" ? "0" : "auto",
                left: lang == "en" ? "auto" : "0",
              }}
            >
              <img
                src={lang === "en" ? newOffer : bestOfferAr}
                alt="best seller"
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
        <Box
          sx={{
            textAlign: "center",
            py: "16px",
            borderTop: "1px solid #DFDFDF !important",
            background: card[100],
            boxShadow: isHovering
              ? "2px 2px 10px 0px rgba(0, 0, 0, 0.06)"
              : "0",
            cursor: "pointer",
            borderRadius: isHovering ? "0px" : "0", // "8px" : "0"
          }}
          onClick={() => {
            if (product?.quantity > 0) {
              handleAddToCart();
            } else {
              toast.error(t("Restocking"));
            }
          }}
        >
          <Typography
            className="main-text"
            component={"span"}
            sx={{
              textAlign: "center",
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* Restocking */}
            <span style={{ display: "inline-block", margin: "0 8px" }}>+</span>
            {product?.quantity > 0 ? t("AddToCart") : t("Restocking")}
            {/* {t("AddToCart")} */}
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0, 0, 0, 0.35)",
            display: product?.quantity === 0 ? "flex" : "none", // make it flex if out of stock  product
            alignItems: "center",
            justifyContent: "center",
            visibility: "visible",
            opacity: "1",
            transition: "all ease-out  300ms",
            borderRadius: "8px 8px 8px 8px",
            cursor: "pointer",
          }}
          onClick={() =>
            navigate(`/shop/${getSlugFiy(product?.name)}/?id=${product?.id}`)
          }
        >
          <img src={outOfStock} alt="out of stock" width={"50%"} />
        </Box>
      </Box>
    </>
  );
};

export default ProductCard;
